<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-top="22"
    :nudge-left="173"
    tile
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="buttonText"
        v-bind="attrs"
        v-on="on"
        readonly
        dense
        outlined
        background-color="white"
        hide-details
        color="msaBlue"
        append-icon="mdi-menu-down"
        :style="{ width: '360px' }"
      >
        <template v-slot:append>
          <v-icon class="clickable" color="black" @click="menu = true">
            mdi-menu-down
          </v-icon>
        </template>
      </v-text-field>
    </template>

    <v-card tile width="550" class="pt-3">
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="localFrom"
                dense
                label="From"
                :rules="rules.from"
                clearable
                prepend-icon="mdi-calendar"
                @click:clear="manualInput"
                @input="manualInput"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="localTo"
                dense
                label="To"
                :rules="rules.to"
                clearable
                prepend-icon="mdi-calendar"
                @click:clear="manualInput"
                @input="manualInput"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item-group
                v-model="localFilterIntervalId"
                mandatory
                @change="updateFromAndTo"
              >
                <v-row no-gutters>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-4 mb-2 font-weight-medium">
                        Other
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="1">
                          <v-list-item-content>
                            <v-list-item-title class="text-body-2">
                              All time
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="2">
                          <v-list-item-content>
                            <v-list-item-title>Custom</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-4 mb-2 font-weight-medium">
                        Recent
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="3">
                          <v-list-item-content>
                            <v-list-item-title>Today</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="4">
                          <v-list-item-content>
                            <v-list-item-title>Yesterday</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-4 mb-2 font-weight-medium">
                        Week
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="5">
                          <v-list-item-content>
                            <v-list-item-title> This week </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="7">
                          <v-list-item-content>
                            <v-list-item-title>Last 7 days</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="6">
                          <v-list-item-content>
                            <v-list-item-title> Last week </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="8">
                          <v-list-item-content>
                            <v-list-item-title>Last 14 days</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-4 mb-2 font-weight-medium">
                        Month
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="9">
                          <v-list-item-content>
                            <v-list-item-title> This month </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="11">
                          <v-list-item-content>
                            <v-list-item-title>Last 30 days</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="10">
                          <v-list-item-content>
                            <v-list-item-title> Last month </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-4 mb-2 font-weight-medium">
                        Quarter
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="12">
                          <v-list-item-content>
                            <v-list-item-title>
                              This quarter
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12">
                        <v-list-item :value="13">
                          <v-list-item-content>
                            <v-list-item-title>Last quarter</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  name: 'CustomDatePicker',
  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    filterIntervalId: {
      type: Number,
    },
  },
  computed: {
    formattedFromForLabel() {
      return this.localFrom
        ? DateTime.fromFormat(this.localFrom, 'yyyy-LL-dd').toFormat(
            'LLL d, yyyy',
          )
        : '';
    },
    formattedToForLabel() {
      return this.localTo
        ? DateTime.fromFormat(this.localTo, 'yyyy-LL-dd').toFormat(
            'LLL d, yyyy',
          )
        : '';
    },
    todayForLabel() {
      return `Today (${DateTime.local().toFormat('LLL d, yyyy')})`;
    },
    yesterdayForLabel() {
      return `Yesterday (${DateTime.local()
        .minus({ days: 1 })
        .toFormat('LLL d, yyyy')})`;
    },
    rangeText() {
      return `${this.formattedFromForLabel} - ${this.formattedToForLabel}`;
    },
    buttonText() {
      switch (this.localFilterIntervalId) {
        case this.$constants.FILTER_INTERVALS.ALL_TIME:
          return 'All time';
        case this.$constants.FILTER_INTERVALS.CUSTOM:
          return this.rangeText;
        case this.$constants.FILTER_INTERVALS.TODAY:
          return this.todayForLabel;
        case this.$constants.FILTER_INTERVALS.YESTERDAY:
          return this.yesterdayForLabel;
        case this.$constants.FILTER_INTERVALS.THIS_WEEK:
          return `This week (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_WEEK:
          return `Last week (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_7_DAYS:
          return `Last 7 days (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_14_DAYS:
          return `Last 14 days (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.THIS_MONTH:
          return `This month (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_MONTH:
          return `Last month (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_30_DAYS:
          return `Last 30 days (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.THIS_QUARTER:
          return `This quarter (${this.rangeText})`;
        case this.$constants.FILTER_INTERVALS.LAST_QUARTER:
          return `Last quarter (${this.rangeText})`;
        default:
          return 'Select date range';
      }
    },
  },
  data() {
    return {
      menu: false,
      rules: {
        from: [
          (v) =>
            !v ||
            /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
              v,
            ) ||
            'Date must be in the following format: YYYY-MM-DD',
          (v) =>
            !v ||
            DateTime.fromFormat(v, 'yyyy-LL-dd').isValid ||
            'Invalid date',
          (v) => {
            // if from date is not empty and to date is not empty, from date must be earlier than to date
            if (
              v &&
              this.localTo &&
              DateTime.fromFormat(this.localTo, 'yyyy-LL-dd').isValid
            ) {
              return (
                DateTime.fromFormat(v, 'yyyy-LL-dd') <=
                  DateTime.fromFormat(this.localTo, 'yyyy-LL-dd') ||
                'From date cannot be later than to date'
              );
            }
            return true;
          },
          (v) => {
            if (
              this.localFilterIntervalId ==
                this.$constants.FILTER_INTERVALS.CUSTOM &&
              !v
            ) {
              return 'From date is required';
            }
            return true;
          },
        ],
        to: [
          (v) =>
            !v ||
            /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
              v,
            ) ||
            'Date must be in the following format: YYYY-MM-DD',
          (v) =>
            !v ||
            DateTime.fromFormat(v, 'yyyy-LL-dd').isValid ||
            'Invalid date',
          (v) => {
            // if to date is not empty and from date is not empty, to date must be later than from date
            if (
              v &&
              this.localFrom &&
              DateTime.fromFormat(this.localFrom, 'yyyy-LL-dd').isValid
            ) {
              return (
                DateTime.fromFormat(v, 'yyyy-LL-dd') >=
                  DateTime.fromFormat(this.localFrom, 'yyyy-LL-dd') ||
                'To date cannot be earlier than from date'
              );
            }
            return true;
          },
        ],
      },
      localFrom: '',
      localTo: '',
      localFilterIntervalId: 1,
      timeout: null,
    };
  },
  methods: {
    calculateFromAndToBasedOnFilterInterval(filterIntervalId) {
      const result = { from: '', to: '' };
      switch (filterIntervalId) {
        case this.$constants.FILTER_INTERVALS.ALL_TIME:
          result.from = '';
          result.to = '';
          break;
        case this.$constants.FILTER_INTERVALS.TODAY:
          result.from = DateTime.local().toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.YESTERDAY:
          result.from = DateTime.local()
            .minus({ days: 1 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ days: 1 })
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_WEEK:
          result.from = DateTime.local().startOf('week').toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_WEEK:
          result.from = DateTime.local()
            .minus({ weeks: 1 })
            .startOf('week')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ weeks: 1 })
            .endOf('week')
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_7_DAYS:
          result.from = DateTime.local()
            .minus({ days: 7 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_14_DAYS:
          result.from = DateTime.local()
            .minus({ days: 14 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_MONTH:
          result.from = DateTime.local()
            .startOf('month')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_MONTH:
          result.from = DateTime.local()
            .minus({ months: 1 })
            .startOf('month')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ months: 1 })
            .endOf('month')
            .toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_30_DAYS:
          result.from = DateTime.local()
            .minus({ days: 30 })
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.THIS_QUARTER:
          result.from = DateTime.local()
            .startOf('quarter')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local().toFormat('yyyy-LL-dd');
          break;
        case this.$constants.FILTER_INTERVALS.LAST_QUARTER:
          result.from = DateTime.local()
            .minus({ quarters: 1 })
            .startOf('quarter')
            .toFormat('yyyy-LL-dd');
          result.to = DateTime.local()
            .minus({ quarters: 1 })
            .endOf('quarter')
            .toFormat('yyyy-LL-dd');
          break;
        default:
          break;
      }
      return result;
    },
    init() {
      this.localFilterIntervalId = this.filterIntervalId;
      if (this.filterIntervalId != this.$constants.FILTER_INTERVALS.CUSTOM) {
        const result = this.calculateFromAndToBasedOnFilterInterval(
          this.filterIntervalId,
        );
        this.localFrom = result.from;
        this.localTo = result.to;
      } else {
        this.localFrom = this.from;
        this.localTo = this.to;
      }
    },
    updateFromAndTo(e) {
      const result = this.calculateFromAndToBasedOnFilterInterval(e);
      this.$nextTick(() => {
        this.localFrom = result.from;
        this.localTo = result.to;
        if (e != this.$constants.FILTER_INTERVALS.CUSTOM) {
          this.$emit('update', {
            from: this.localFrom,
            to: this.localTo,
            filterIntervalId: e,
          });
        }
      });
    },
    manualInput() {
      if (
        this.localFilterIntervalId != this.$constants.FILTER_INTERVALS.CUSTOM
      ) {
        this.localFilterIntervalId = this.$constants.FILTER_INTERVALS.CUSTOM;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.$emit('update', {
            from: this.localFrom || '',
            to:
              !this.localTo || ''
                ? DateTime.local().toFormat('yyyy-LL-dd')
                : this.localTo,
            filterIntervalId: this.localFilterIntervalId,
          });
        }
      }, 1500);
    },
  },
};
</script>

<style></style>
